import ModalTransaction from './components/ModalTransaction/ModalTransaction';
import Toast from './components/Toast/Toast';
import { StoreProvider } from './store/storeContext';
export default function Root(props) {
  return <section>

    <StoreProvider>
      {props.children}
      <ModalTransaction />
      <Toast />
    </StoreProvider>


  </section>;
}
