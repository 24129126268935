import * as React from 'react';

import './ModalTransaction.css'
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography, TextField, Divider, InputLabel, MenuItem, FormControl, Select, Button, List, ListItem, Backdrop, CircularProgress, Autocomplete, Stack, Alert } from '@mui/material'
import 'bootstrap/dist/css/bootstrap.min.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Offcanvas } from 'react-bootstrap';
import { LuContainer } from "react-icons/lu";
import useModalTransactionController from './ModalTransactionController';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker  } from '@mui/x-date-pickers/MobileDateTimePicker';

import { UTCtoMMDDYYYYHHMM } from '../../utils/utils';


const ModalTransaction = () => {


    const { show, maxDateTime,handleClose, minDateTime, handleChange, ChassisSelected, ingateType, trueContainers, TransactionObject, backdropState, ChassisList, handleflipChassis, doingFlip, dateFlip, setDateFlip, newScac } = useModalTransactionController()




    return (<>
        <Offcanvas show={show} placement={'end'} onHide={handleClose} style={{ top: '64px' }}>
            <Offcanvas.Header style={{ backgroundColor: '#23224F' }}>
                <Box sx={{ display: 'flex', width: '100%' }} flexGrow={1}>
                    <Grid container spacing={2} style={{ display: 'contents' }}>
                        <Grid xs={3} sm={2} md={1}>
                            <ArrowBackIosNewIcon onClick={() => handleClose()} id="arrowIcon" />
                        </Grid>
                        <Grid xs={8} sm={9} md={11} display={'flex'}>
                            <LuContainer style={{ color: 'white', scale: '1.5', marginRight: '2%', alignSelf: 'center' }} />
                            <Typography variant="h5" style={{ color: 'white', paddingLeft: '3%' }}>Container Details</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Offcanvas.Header>
            <Offcanvas.Body style={{
                position: 'relative',
                backgroundColor: ChassisList ? 'transparent' : 'rgba(0, 0, 0, 0.2)',
                minHeight: '400px'
            }}>
                {ChassisList ? (<>        </>) : (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <CircularProgress color="inherit" style={{ color: '#ff5252' }} />
                    </div>
                )}
                < Grid container spacing={3} style={{ padding: '6% 0 6% 0' }}>
                    <Grid xs={5} display={'flex'}>
                        <Typography variant="h6" id="TitleIngate" paddingLeft='20%'>INGATE TYPE</Typography>
                    </Grid>
                    <Grid xs={7} display={'flex'}>
                        <img src={ingateType} style={{ scale: '1.2', color: '#4BA5FF' }}></img>
                    </Grid>
                    <Grid sm={12} md={6}>
                        <TextField id="outlined" value={TransactionObject ? TransactionObject.in_gate_register.transaction_type : ''} label="Transaction Type" variant="outlined" InputProps={{
                            style: { borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid sm={12} md={6}>
                        <TextField id="outlined" value={TransactionObject ? UTCtoMMDDYYYYHHMM(TransactionObject.in_gate_register.arrival_datetime) : ''} label="Arrival" variant="outlined" InputProps={{
                            style: { borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid xs={12}>
                        <Divider id="DividerCss" />
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>
                        <TextField id="outlined" label="SCAC Code"
                            value={TransactionObject ? TransactionObject.scac_code : ''}
                            variant="outlined" InputProps={{
                                style: { borderRadius: '10px' }
                            }} disabled />
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>
                        <TextField id="outlined" label="Location" value={TransactionObject ? TransactionObject.location : ''} variant="outlined" InputProps={{
                            style: { borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>
                        <TextField id="outlined" label="Cont/Trl Num" value={TransactionObject ? TransactionObject.container_number : ''} variant="outlined" InputProps={{
                            style: { borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>
                        <TextField id="outlined" label="Chassis N" value={TransactionObject ? TransactionObject.chassis_number : ''} variant="outlined" InputProps={{
                            style: { color: '#4BA5FF', borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>
                        <TextField id="outlined" label="Spot" value={TransactionObject ? TransactionObject.spot : ''} variant="outlined" InputProps={{
                            style: { borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>
                        <TextField id="outlined" label="New SCAC" value={TransactionObject ? newScac : ''} variant="outlined" InputProps={{
                            style: { borderRadius: '10px' }
                        }} disabled />
                    </Grid>
                    <Grid sm={12} md={6}></Grid>
                    <Grid xs={12}>
                        <Divider id="DividerCss" />
                    </Grid>
                    <Grid sm={12} xs={12}>
                        <Typography variant="h6" >Flip/Chassis change</Typography>
                    </Grid>
                    <Grid sm={12} md={6} width={'100%'}>

                        <Autocomplete
                            id="combo-box-demo"
                            options={ChassisList ? ChassisList.filter((chassisItem) => chassisItem.chassis_number) : []}
                            getOptionLabel={(option) => option.chassis_number}
                            value={ChassisSelected || null}
                            onChange={(event, newValue) => {
                                handleChange({ target: { value: newValue } });
                            }}
                            renderInput={(params) => <TextField {...params} label="Chassis N" display={'flex'} />}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <div style={{ display: 'flex' }}>
                                        <p style={{ fontSize: '70%' }}>{option.chassis_number}</p>
                                        {option.container_number && (
                                            <p style={{ fontSize: '60%', color: '#9c9c9c' }}>- with container {option.container_number}</p>
                                        )}
                                    </div>
                                </li>
                            )}
                        />

                    </Grid>
                    <Grid sm={12} md={6} display={'flex'} width={'100%'}>
                        <Button variant="contained" fullWidth startIcon={<SyncAltIcon />} sx={{ borderRadius: '10px' }} onClick={(e) => handleflipChassis()}>Flip Chassis</Button>
                    </Grid>
                    <Grid sm={12} md={12} display={'flex'} width={'100%'}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['MobileTimePicker']}>
                                <MobileDateTimePicker  label="Flip Date-Time" value={dateFlip} onChange={(newDate) => setDateFlip(newDate)}  maxDateTime={maxDateTime} minDateTime={minDateTime}  />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>

                    <Grid xs={12}>
                        <Divider id="DividerCss" />
                    </Grid>
                    {trueContainers ? <Grid xs={12}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            {/* <Alert severity="error">Containers not available to the new SCAC code</Alert> */}

                        </Stack>
                    </Grid> : null}
                    {doingFlip ? (<Grid xs={12} textAlign={'center'}>
                        <CircularProgress color="success" />
                    </Grid>) : null}
                </Grid>
            </Offcanvas.Body>
        </Offcanvas >
    </>
    )
}
export default ModalTransaction



{/* <Grid sm={12}>
                        <Typography variant="h6" >Chassis Usage</Typography>
                    </Grid>
                    <Grid sm={12}>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', display: 'contents' }}
                        >
                            <ListItem sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                <Grid container spacing={2} columns={20}>
                                    <Grid xs={12} sm={6} md={6}>
                                        <TextField id="standard-basic" label="Chassis N" variant="standard" inputProps={{ readOnly: true,id:"text-color", style: { textAlign: 'center', fontSize: '80%' } }} value={'Hola'} />

                                    </Grid>
                                    <Grid xs={12} sm={6} md={3}>
                                        <TextField id="standard-basic" label="Start Date" variant="standard" inputProps={{ readOnly: true, style: { textAlign: 'center', fontSize: '80%' } }} />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={3}>
                                        <TextField id="standard-basic" label="End Date" variant="standard" inputProps={{ readOnly: true, style: { textAlign: 'center', fontSize: '80%' } }} />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={3}>
                                        <TextField id="standard-basic" label="Days" variant="standard" inputProps={{ readOnly: true, style: { textAlign: 'center', fontSize: '80%' } }} />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={5}>
                                        <TextField id="standard-basic" label="User" variant="standard" inputProps={{ readOnly: true, style: { textAlign: 'center', fontSize: '80%' } }} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </Grid> */}