import * as React from 'react'
import { getTransactionInfoById, getChassisByScac, postTransactionFlip } from '../../services/services'
import StoreContext from '../../store/storeContext';
import { UTCtoMMDDYYYYHHMM } from '../../utils/utils';
import dayjs from 'dayjs';
const useModalTransactionController = () => {
    const [show, setShow] = React.useState(false);
    const [maxDateTime, setMaxDateTime] = React.useState(dayjs(new Date))
    const [minDateTime, setMinDateTime] = React.useState(dayjs(new Date))
    const [ChassisSelected, setChassisSelected] = React.useState('');
    const [TransactionObject, setTransactionObject] = React.useState(null);
    const [ingateType, setIngateType] = React.useState('');
    const [backdropState, setBackdropState] = React.useState(true);
    const [ChassisList, setChassisList] = React.useState(null)

    const [transactionId, setTransactionId] = React.useState(null)
    const { setToast, user, setUser } = React.useContext(StoreContext);
    const [doingFlip, setDoingFlip] = React.useState(false)
    const [dateFlip, setDateFlip] = React.useState(null)
    const [newScac, setNewScac] = React.useState('')
    const [trueContainers, setTrueContainers] = React.useState(null)
    React.useEffect(() => {
        const today = new Date()
        setDateFlip(dayjs(today))
    }, [])
    const handleClose = () => {
        setChassisSelected('')
        setTransactionObject(null)
        setIngateType('')
        setShow(false)
        setChassisList(null)
        setBackdropState(true)
        setDoingFlip(false)
    };
    const handleShow = () => setShow(true);

    const handleChange = (event) => {
        setChassisSelected(event.target.value);

    };
    const flipChassisHandler = (e) => {
        setShow(true)
        getTransactionInfo(e.detail.YardTransactionId)
        if (e.detail.NewScacCode !== 'N/A')
            getChassisByScacCode(e.detail.NewScacCode, e.detail.Chassis)
        else
            getChassisByScacCode(e.detail.ScacCode, e.detail.Chassis)
        setTransactionId(e.detail.YardTransactionId)
        setNewScac(e.detail.NewScacCode)
    };
    React.useEffect(() => {
        window.addEventListener('flipChassis', flipChassisHandler);
        return () => {
            window.removeEventListener('flipChassis', flipChassisHandler);
        };
    }, []);
    const getTransactionInfo = async (id) => {
        const response = await getTransactionInfoById(id);
        let data = response.data.data;
        const date = UTCtoMMDDYYYYHHMM(data.in_gate_register.arrival_datetime)
        setMinDateTime(dayjs(date))
        setTransactionObject(data);
    }


    React.useEffect(() => {

        if (TransactionObject) {
            let IngateType = ''

            if (TransactionObject.container_number && !TransactionObject.chassis_number) {

                IngateType = '__Loaded - grounded.png'
            }
            else if (!TransactionObject.container_number && TransactionObject.chassis_number) {

                IngateType = 'Chassis solo.png'
            }
            else if (!TransactionObject.container_number && !TransactionObject.chassis_number) {

                IngateType = 'Chassis solo.png'
            }
            else if (TransactionObject.chassis_number && TransactionObject.container_number) {
                IngateType = 'Loaded.png'
            }
            setIngateType(process.env.REACT_APP_DP_BANK_IMG_URL + IngateType)
            setBackdropState(false)

        }

    }, [TransactionObject])


    const getChassisByScacCode = async (scac_code, chassis) => {
        const scac = scac_code.replace(/"/g, '');
        const response = await getChassisByScac(scac)
        if (response.data.success) {
            let newData = [{ container_number: 'GROUNDED', chassis_number: 'GROUNDED' }];
            const existingData = response.data.data.container_chassis_items;
            const filteredData = existingData.filter((item) => item.chassis_number !== null && item.chassis_number !== chassis);
            if (filteredData.length === 0) {
                setTrueContainers(true)
            }
            else {
                setTrueContainers(false)
            }
            newData = [...newData, ...filteredData];
            // Añadir el elemento adicional


            setChassisList(newData);

        } else {
            // En caso de error o falta de éxito en la respuesta
            setChassisList([{ container_number: 'GROUNDED', chassis_number: 'GROUNDED' }]);
            setTrueContainers(true)
        }
    }

    const handleConvertToUtc = (date) => {
        const dateToConvert = new Date(date)

        const fechaUTC = new Date(dateToConvert.toISOString());
        return fechaUTC
    }




    const handleflipChassis = async () => {
        let chassis = null
        if (ChassisSelected.chassis_number !== 'GROUNDED') {
            chassis = ChassisSelected.chassis_number
        }
        setDoingFlip(true)
        let transaction = {
            transactionIdToFlip: transactionId,
            chassisToFlip: chassis,
            UserRequest: user.email[0],
            flipDateTime: handleConvertToUtc(dateFlip)
        }
        const response = await postTransactionFlip(transaction)
        if (response.data.success) {
            setToast({
                state: true,
                severity: 'success',
                message: response.data.message,
            })
            setDoingFlip(false)
            setShow(false)
            const evento = new CustomEvent('chassisFliped');
            window.dispatchEvent(evento);
        } else {
            setToast({
                state: true,
                severity: 'error',
                message: response.data.message,
            })
            setDoingFlip(false)
            setShow(false)

        }



    }
    return {
        handleClose,
        handleShow,
        show,
        handleChange,
        ChassisSelected,
        TransactionObject,
        ingateType,
        backdropState,
        ChassisList,
        handleflipChassis,
        doingFlip,
        dateFlip,
        setDateFlip,
        trueContainers,
        newScac,
        maxDateTime,
        minDateTime
    }
}
export default useModalTransactionController
