import * as React from 'react';
import Cookies from 'universal-cookie';
const StoreContext = React.createContext();
export default StoreContext;

export const StoreProvider = ({ children }) => {


    const cookies = new Cookies(null, { path: '/' });
    const [toast, setToast] = React.useState({
        state: false,
        severity: 'success',
        message: 'Your transaction was successful',
    })
    const [user, setUser] = React.useState(null)
    React.useEffect(() => {
        if (cookies.get("sessionCosmos")) {
            setUser(cookies.get("sessionCosmos"))
        } else {
            cookies.addChangeListener((session) => {
                if (session.name === "sessionCosmos") {
                    if (session.value !== undefined) {
                        setUser(session.value)
                    }
                }
            })
        }
    }, [])

    return (
        <StoreContext.Provider value={
            {
                toast,
                setToast,
                user,
                setUser
            }
        }>
            {children}
        </StoreContext.Provider>
    );
}