import axios from "axios";

export const getTransactionInfoById = async (id) => {
    const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER
    };
    const config = {
        headers: headers,
        method: "get",
        url: process.env.REACT_APP_GET_TRANSACTION_BY_ID,
        params: {
            transaction_id: id
        }
    };
    return axios(config);
}

export const getChassisByScac = async (scac_code) => {
    const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER
    };
    const config = {
        headers: headers,
        method: "get",
        url: process.env.REACT_APP_GET_YARD_ITEMS_BY_SCAC_CODE,
        params: {
            scac_code: scac_code
        }
    };
    return axios(config);
}

export const postTransactionFlip = async (transaction) => {

    const headers = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER
    };
    const config = {
        headers: headers,
        method: "post",
        url: process.env.REACT_APP_FLIP_TRANSACTION,
        data: transaction,
        timestamp: new Date().getTime(),
    };
    return axios(config);

}