export const UTCtoMMDDYYYYHHMM = (utcDate) => {
    // Define the UTC time you receive
    const utcTime = new Date(utcDate);
    // Obtener el desplazamiento de la zona horaria en minutos
    const offset = utcTime.getTimezoneOffset();
    
    // Convertir el desplazamiento de minutos a milisegundos y ajustar la hora UTC
    utcTime.setMinutes(utcTime.getMinutes() - offset);   
    // Obtener los componentes de fecha y hora en la zona horaria local
    const day = utcTime.getDate();
    const month = utcTime.getMonth() + 1; // Los meses en JavaScript son basados en cero
    const year = utcTime.getFullYear();
    const hours = utcTime.getHours();
    const minutes = utcTime.getMinutes();
    
    // Formatear la hora local en MM/DD/YYYY HH:MM
    const localTimeFormatted = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return localTimeFormatted;
};