import * as React from 'react';
import StoreContext from '../../store/storeContext';

const useToastController = () => {
    const { toast,setToast } = React.useContext(StoreContext);

    return {
        toast,
        setToast
    };
}
export default useToastController;