import { Snackbar, IconButton, Alert } from '@mui/material'
import useToastController from './ToastController'

const Toast = () => {
  const { toast,setToast } = useToastController();
  return (<>
    <Snackbar
      open={toast.state}
      autoHideDuration={6000}
      onClose={() => setToast({ ...toast, state: false })}
    >
      <Alert severity={toast.severity}>{toast.message}</Alert>
    </Snackbar>

  </>
  )

}
export default Toast